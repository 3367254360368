export default {
    addNew: {
        recharge: "Please contact customer service to obtain" ,
        bank_type1: "Wallet",
        bank_type0: "Bank",
        phone_number: "PhoneNumber",
        realname: "Realname",
        id_number: "Id Number",
        bank_code: "Bank Code",
        bank_address: "Bank Address",
        bank_branch: "Bank Branch",
        bank_number: "Bank Number",
        bank_name: "Bank Name",
        add_bank: "Add Bank",
        my_wallet: "내 지갑",
        add_wallet: "지갑 추가",
        place_wallet_type: "지갑 종류를 선택하십시오.",
        place_wallet_name: "지갑 이름을 입력하십시오.",
        place_wallet_address: "지갑 주소를 입력하십시오.",
        reference_annualization: "참조 연화",
        state: "상태",
        term: "기간",
        depositState0: "뜨겁게 진행 중입니다.",
        depositState1: "기대해주세요.",
        label_amount: "금액",
        place_amount: "금액을 입력하세요",
        estimated_income: "예상 수입",
        available_assets: "가용 자산",
        cancel: "취소",
        all: "모두",
        min_amount: "최소 금액",
        daily: "일일 생산량",
        waiting_time: "대기 시간",
        product_name: "제품 이름",
        buy_total: "총 구매 수량",
        earnings: "누적 이익",

        myMessage: "내 메시지",
        place_card_back: "신분증 뒷모습 업로드 클릭",
        side1: "주문 관리",
        side2: "암호 수정",
        side3: "언어",
        side4: "로그인 종료",
        product: "제품",
        direction: "방향",
        cycle: "거래 기간",
        long_buy: "많이 사세요",
        short_sell: "공매",
        open_time: "창고 오픈 시간",
        close_time: "창고 정리 시간",
        open_price: "창고 개설 가격",
        close_price: "평가 가격",
        number: "수량",
        profit_loss: "손익",
        commission: "수수료",
        account_type: "장부 유형",
        type1: "코인 계좌",
        type2: "법화 계좌",
        type3: "계약 계정",
        type4: "옵션 계정",
        type5: "재테크 계",
        message1: "금액을 입력하십시오",
        message2: "계좌 잔액 부족",
        place_transPsd: "거래 암호를 입력하십시오.",
        countpath: "카운트다운 종료 후 주문 페이지로 자동 이동",
        nowpath: "즉시 점프",
    },
    loan: {
        walletLink: "지갑에서 이 연결을 켜십시오！",
        connectWallet: "지갑 연결",

        loan_state_4: "실패",
        loan_state_3: "상환 완료",
        loan_state_2: "지급됨",
        loan_state_1: "승인 대기 중",
        repay_state_2: "상환 완료",
        repay_state_1: "미상환",
        repay_create: "대출 기간",
        repay_state: "업무 상태",
        repay_times: "단계",
        repay_time: "상환 기간",
        repay_money: "총 상환액",
        repay_total: "대출 금액",
        repay_pay: "상환 기록",
        repay: "대출 기록",
        loan_pic4: "사진 신분증",
        loan_pic3: "은행 명세서",
        loan_pic2: "소득 증명",
        loan_pic1: "부동산 소유권 증명서",
        loan_pic: "신용 대출(사진이 선명하게 보이는지 확인하세요)",
        loan_pics: "신분증 양식",
        loan_pics_view: "보려면 클릭",
        loan_repay_method_1: "일시 원금 및 이자 상환" ,
        loan_repay: "상환 방법",
        loan_apr: "일일 이자율",
        loan_time: "대출 주기",
        loan_type_1: "일",
        loan_type_2: "Moon",
        loan_money: "대출 금액",
        loan: "학자금 대출",
    },
    foot: {
        nav1: "홈 페이지",
        nav2: "시세",
        nav3: "거래",
        nav4: "화폐",
        nav5: "자산",
    },
    account: {
        verifyPass: "Verify Password",
        verifySuccess: "인증 통과",
        verifyFailed: "인증되지 않음",
        login: "로그인",
        register_email: "메일박스",
        register_phone: "핸드폰",
        label_phone: "핸드폰 번호",
        place_phone: "핸드폰 번호를 입력하십시오.",
        label_eMail: "메일함",
        place_eMail: "이메일 주소를 입력하세요",
        tip_eMail: "이메일 주소를 정확하게 입력하세요",
        label_password: "비밀번호",
        place_password: "비밀번호를 입력하세요",
        tip_password: "올바른 비밀번호를 입력하세요",
        no_account: "아직 계정이 없으신가요?" ,
        register: "등록하세요",
        send: "인증 코드 보내기",
        label_code: "이메일 인증 코드",
        place_code: "이메일 인증 코드",
        tip_code: "이메일 인증 코드를 입력하세요",
    },
    assets: {
        title: "자산",
        total_assets: "총자산 환산",
        recharge: "충전",
        withdraw: "동전 인출",
        available: "사용 가능",
        freeze: "동결",
        total: "합계",
        label_bank: "화폐 종류",
        place_bank: "코인을 선택하세요",
        tip_bank: "코인을 선택하세요",
        label_wallet: "지갑",
        place_wallet: "지갑을 선택하세요",
        tip_wallet: "지갑을 선택하세요",
        label_amount: "금액",
        place_amount: "금액을 입력하세요",
        tip_amount: "금액을 정확히 입력하십시오 ",
        label_password: "암호",
        place_password: "암호를 입력하십시오 ",
        tip_password: "올바른 암호를 입력하십시오 ",
        label_balance: "사용 가능 USDT",
        all: "모두 현금 인출",
        no_password: "자금 보안을 위해 설정",
        withdraw_psd: "비밀번호 인출",
        type1: "코인 계좌",
        type2: "법화 계좌",
        type3: "계약 계정",
        type4: "초고속 계약 계정",
        type5: "재테크 계좌",
        convert: "환산",
        now_currency: "현재 화폐 종류",
        link_type: "체인 유형",
        recharge_address: "충전 주소",
        copy: "주소 복사",
        palce_amount: "충전 수량을 입력하세요",
        label_address: "지갑 주소",
        palce_address: "지갑 주소를 입력하십시오 ",
        submit_process: "감사 제출",
        tip1: "위 주소에 USDT가 아닌 자산을 충전하지 마십시오  그렇지 않으면 자산을 복구할 수 없습니다 。",
        tip2: "USDT 충전은 simple send 방법만 지원하며 다른 방법의 충전을 사용하면 잠시 입금할 수 없습니다  양해해 주십시오。",
        tip3: "귀하가 상기 주소에 충전 후, 전체 네트워크 노드의 확인, 1회 네트워크 확인 후 입금, 6회 네트워크 확인 코인 인출 가능。",
        tip4: "충전 주소는 자주 변경되지 않으며 재충전할 수 있습니다 변경이 있으면 가능한 한 인터넷 공지나 메일로 알려드리겠습니다。",
        tip5: "정보가 변조 또는 유출되지 않도록 컴퓨터와 브라우저의 보안을 반드시 확인하십시오 。",
        record_title: "거래 기록",
        label_number: "수량",
        label_record: "기록",
        label_time: "시간",
        label_price: "가격",
        label_volume: "재다",
        transfer_title: "회전",
        from: "부터",
        to: "다음",
        transfer_record: "레코드 스왑",
        label_withAddress: "동전 인출 주소",
        place_withAddress: "먼저 코인 인출 주소를 설정하십시오 ",
        deposit_title: "채굴을 질권 하다",
        buy: "구매",
        sell: "판매",
        week: "주기",
        daily_dividend: "일일 배당금",
        deposit_log: "채굴 기록을 질권 하다",
        userinfo_nav1: "인증",
        userinfo_nav2: "내 공유 링크",
        userinfo_nav3: "내 인증 코드",
        userinfo_nav4: "보안 센터",
        userinfo_nav5: "수금 방식",
        userinfo_nav6: "온라인 고객 서비스",
        userinfo_nav7: "우리에 대해",
        userinfo_nav8: "다운로드 APP",
        userinfo_nav9: "도움말 센터",
        logout: "로그인 종료",
        second: "초 계약",
        second_title: "초고속 계약",
        invite: "초대",
        legal: "법화 거래",
        ieo: "IEO",
        state0: "모두",
        state1: "예매 중",
        state2: "청약 중",
        state3: "종료",
        history_record: "기록",
        legal_tab1: "사겠습니다 ",
        legal_tab2: "팔 거야",
        transType: "거래 유형",
        order_state: "주문 상태",
        order_state1: "미완성",
        order_state2: "지불됨",
        order_state3: "연기 확인",
        order_state4: "권익옹호 중",
        order_state5: "취소됨",
        order_state6: "완료됨",
        reset: "재설정",
        hand_number: "거래 건수",
        long: "많이 하다",
        short: "비우다",
        confirm_order: "주문 확인",
        type: "유형",
        multiple_number:"배수",
        now_entrust: "현재 위임",
        all_entrust: "모두 위임",
        history_entrust: "역사적 의뢰",
        turnover: "거래액",
        operate: "작업",
        delete: "삭제",
        order_details: "주문 상세 정보",
        orderDetails_tab1: "접수 중",
        orderDetails_tab2: "거래 중",
        orderDetails_tab3: "창고 정리됨",
        orderDetails_tab4: "취소됨",
        risk_rate: "위험률",
        total_pl: "창고 보유 총손익",
        closing_position: "원클릭 창고 정리",
        position_list: "보유 목록",
        order_record: "주문 기록",
        profit_rate: "수익률",
        buy_long: "매입",
        buy_short: "매수하다",
        trans_mode: "거래 모델",
        opening_quantity: "창고 개설 수량",
        opening_number: "창고 개설 시간",
        place_transMount: "거래 한도를 입력하십시오 ",
        account_balance: "계좌 잔액",
        privacy_policy: "개인 정보 보호 정책",
        contact_us: "연락처",
        feedback: "사용자 피드백",
        feedback_type: "피드백 유형",
        feedback_type1: "인출 관련",
        feedback_type2: "블록체인 관련",
        label_title: "제목",
        place_title: "제목을 입력하십시오 ",
        label_cont: "피드백 내용",
        place_cont: "내용을 입력하십시오 ",
        reset_password: "비밀번호 재설정",
        personal_center: "개인 센터",
        to_authenticate: "인증하러 가다",
        authenticate_tip1: "·인증 후 코인 인출 한도 증가",
        authenticate_tip2: "·실명 인증을 통과해야만 법화 거래 현금 인출 조작을 진행할 수 있다",
        payment_method: "수금 방식",
        label_reaName: "실명",
        palce_reaName: "실명",
        label_openBank: "구좌 개설 은행",
        palce_openBank: "계좌 개설 줄을 입력하십시오 ",
        label_bankNumber: "은행카드 번호",
        place_bankNumber: "은행카드 번호를 입력하십시오 ",
        lable_branchBank: "계좌 개설 지점",
        place_branchBank: "계좌 개설 지점의 이름을 입력하십시오 ",
        label_IDnumber: "주민등록번호",
        place_IDnumber: "주민등록번호를 입력하십시오 ",
        label_IDimg: "신분증 사진을 손에 쥐다 ",
        label_IDimg2: "뒷면 주민등록증 사진",
        tip_IDimg: "신분증 뒷면 사진 올려주세요 ",
        bankCard: "은행카드",
        details: "상세 정보",
        my_authorization_code: "내 인증 코드",
        my_authorization_code_tip: "내 인증 코드",
        login_password: "로그인 암호",
        trans_password: "거래 암호",
        modify: "수정",
        my_promotional_code: "내 홍보 코드",
        promotional_code: "홍보 코드",
        promotional_code_tip: "위의 QR코드 패턴을 스캔하거나 홍보 코드를 입력하여 등록 확인",
        copy_link: "초대 링크 복사",
        modify_trans_psd: "거래 암호 수정",
        label_email_verify: "메일박스 인증",
        place_email_verify: "인증 코드를 입력하십시오 ",
        send: "발송",
        email_verify_tip: "인증번호가 사서함으로 전송됩니다 ",
        label_bitTitle: "거래 쌍",
    },
    currency: {
        position: "위치",
        drawer_title: "currency_trade",
        now: "현재",
        open: "열기",
        volume: "볼륨",
        height: "최고",
        low: "최저",
        buy: "매수 매수",
        sell: "공매도",
        name: "이름",
        direction: "방향",
        crm_order: "주문 확인",
        now_price: "현재 가격",
        type_time: "배송 시간(더 높은 수익을 위해 왼쪽 슬라이드)",
        second: "초",
        buy_amount: "구매 금액",
        available: "사용 가능",
        service_fee: "수수료",
        min_number: "최소",
        errorTip1: "금액 입력 오류",
        errorTip2: "사용 가능한 잔액 부족",
        auto_jump: "後自動受注ジャンプ",
        keep: "주문 계속",
        revenue: "예상 이익",
    },
    home: {
        quick: "빠른 동전 세척",
        support: "지원",
        nav1: "공유",
        nav2: "지갑",
        nav3: "정보",
        nav4: "튜토리얼",
        nav5: "고객 서비스",
        nav6: "채굴",
        nav7: "회사 소개",
        nav8: "새로운 코인 구독",
        increase : "업 목록",
        volume: "볼륨",
        table_name: "이름",
        table_lastPrice: "최근 가격",
        table_increase: "상승/하락",
        table_volume: "거래량",
    },
    lockming: {
        profit: "최근(일일 수익)",
        cycle: "재무 주기",
        limit: "단일 한도",
        dividend_time: "배당금 지급 시간",
        every_day: "매일",
        funds: "에스크로 자금",
        back: "만기 시 수익률",
        ransom: "조기 상환",
        label_amount: "서약 금액",
        place_amount: "입력하세요,,,",
        available: "사용 가능",
        expect_income: "예상 수입",
        title: "잠긴 채굴",
        tip: "수입을 위한 채굴",
        label_today_income: "오늘 예상 수입",
        label_total_income: "누적 수입",
        label_order: "에스크로에 있는 주문",
        label_number: "단일 금액",
        daily_return: "일일 수익",
        subscribe: "구독",
        position: "포지션",
        label_state: "상태",
        subscribe_time: "구독 시간",
        expiration_time: "만료 시간",
        income: "수입",
        errorTip1: "금액을 입력하세요",
        tip_tit: "조기 탈퇴하시겠습니까?",
    },
    market: {
        spots: "现货",
        contract: "合约",
        currency_trans: "货币交易",
        increase: "涨幅榜",
        volume: "成交量",
        label_name: "名称",
        label_lastPrice: "最新价",
        table_increase: "涨跌幅",
        table_volume: "成交额",
    },
    my: {
        details: "세부 정보",
        help: "도움말 센터",
        information: "정보",
        language: "언어 전환",
        password: {
            account_title: "비밀번호 변경",
            withdraw_title: "비밀번호 변경",
            label_oldPsd: "이전 비밀번호",
            place_oldPsd: "이전 비밀번호를 입력하세요",
            label_newPsd: "새 비밀번호",
            place_newPsd: "새 비밀번호를 입력하세요",
            label_crmPsd: "비밀번호 확인",
            place_crmPsd: "새 비밀번호를 다시 입력하세요",
            withdraw_tip: "출금 비밀번호를 설정하지 않은 경우 비워두세요",
        },
        address: {
            title: "지갑 주소",
            account_number: "계좌 번호",
            add_btn: "주소 추가",
            label_type: "유형 선택",
            place_type: "유형을 선택하세요",
            label_number: "계좌번호",
            place_number: "계좌번호를 입력하세요",
        },
        history: {
            title: "내역",
            recharge_record: "충전 기록",
            withdraw_record: "출금 기록",
            withdraw: "출금",
            number: "숫자",
            currency: "통화",
            state: "상태",
            state0: "보류 중",
            state1: "완료됨",
            state2: "거부됨",
        },
        identity: {
            title: "인증",
            label_country: "국적 선택",
            place_country: "국적을 선택하세요",
            label_name: "이름",
            place_name: "이름을 입력하세요",
            label_id: "ID 번호",
            place_id: "신분증/운전면허증/여권 번호를 입력하세요",
        },
        my: {
            list1: "인증",
            list2: "주문 관리",
            list3: "자금 기록",
            list4: "지갑 주소",
            list5: "언어 전환",
            list6: "친구 초대",
            list7: "고객 서비스",
            list8: "튜토리얼",
            list9: "비밀번호 변경",
            logout: "로그아웃",
        },
        order: {
            title: "위치",
            direction: "방향",
            sell_price: "주문 가격",
            number: "수량",
            type: "배달 시간",
            buy_price: "입찰 가격",
            profit: "이익/손실",
            sell_time: "판매 시간",
        },
        share: {
            title: "친구 초대",
            save_ewm: "QR 코드 저장",
            invite_link: "링크 초대",
            copy_address: "주소 복사",
            invite_code: "등록 코드",
            copy_code: "콘텐츠 복사",
            tip: "QR코드 또는 위의 링크를 통해 등록하세요",
        },
    },
    transaction: {
        spots: "스팟",
        contract: "계약",
        currency_trans: "통화_거래",
        exchange: "플래시",
        sustainable: "영구",
        warehouse: "포지션별",
        jump_lever: "점프 레버리지",
        sell: "판매",
        buy: "구매",
        available: "사용 가능",
        check_price: "제한가격",
        market_price: "시장_가격",
        number: "수량",
        max: "최대",
        bail: "마진",
        buy_long: "매수 매수",
        sell_short: "공매도",
        last_price: "최근 가격",
        NOW_ENTRUST: "현재_위탁",
        now_position: "현재 포지션",
        table_amount: "출금 금액",
        is_reduce: "포지션만 감소",
        shi: "예",
        fou: "아니오",
        position_number: "보유 포지션 수",
        is_profit: "실현된 이익",
        wei: "아직",
        open_price: "시가",
        mark_price: "표시 가격",
        flat_price: "강한_수준_가격",
        trim_lever: "레버리지 조정",
        flat_store: "포지션 닫기",
        trim_bail: "마진 조정",
        reduce_bail: "마진 줄이기",
        NOW_BAIL: "현재 포지션 마진",
        max_add: "최대 증가",
        refer_flat: "조정된 참조 강한 플랫 가격",
        expect_profit: "예상 이익/손실"
    },
    common: {
        confirm: "확인",
        all: "모두",
        close: "취소",
        copy_success: "복사 성공!" ,
        copy_error: "복사 실패!" ,
        place_input: "내용을 입력하세요",
        more: "추가",
        main: "기본",
        sub: "부도",
        success: "성공!",
        cancel_tip: "정말 취소하시겠습니까?" ,
        notice: "알림",
        logout_tip: "종료하시겠습니까?",
        tip: "힌트",
        day: "일",
        empty: "데이터 없음"
    },
    message: {
		cancel_order: "위임 취소 여부?",
		stop_out: "강제 창고 정리 여부?",
	},
    params: {
        transType0: "현물",
        transType1: "계약",
        directionType0: "매입",
        directionType1: "매도",
        lockStateType0: "실행 중",
        lockStateType1: "종료",
        lockStateType2: "취소됨",
	},
    city: {
        albania: "알바니아",
        algeria: "알제리",
        argentina: "아르헨티나",
        armenia: "아르메니아",
        australia: "호주",
        pakistan: "파키스탄",
        austria: "오스트리아",
        bahrain: "바레인",
        belgium: "벨기에",
        bosnia_and_Herzegovina: "보스니아 헤르체고비나",
        brazil: "브라질",
        brunei: "브루나이",
        bulgaria: "불가리아",
        cambodia: "캄보디아",
        canada: "캐나다",
        cameroon: "카메룬",
        chile: "칠레",
        colombia: "콜롬비아",
        costa_Rica: "코스타리카",
        croatia: "크로아티아",
        cyprus: "키프로스",
        czech_Republic: "체코",
        denmark: "덴마크",
        dominican_Republic: "도미니카",
        egypt: "이집트",
        estonia: "에스토니아",
        ethiopia: "에티오피아",
        finland: "핀란드",
        france: "프랑스",
        georgia: "그루지야",
        germany: "독일",
        ghana: "가나",
        greece: "그리스",
        guyana: "가이아나",
        honduras: "온두라스",
        hong_Kong_China: "중국 홍콩",
        hungary: "헝가리",
        iceland: "아이슬란드",
        ireland: "아일랜드",
        italy: "이탈리아",
        india: "인도",
        indonesia: "인도네시아",
        israel: "이스라엘",
        iran: "이란",
        iraq: "이라크",
        japan: "일본",
        kazakstan: "카자흐스탄",
        kenya: "케냐",
        korea: "한국",
        kuwait: "쿠웨이트",
        kyrgyzstan: "키르기스스탄",
        laos: "라오스",
        Latvia: "라트비아",
        lithuania: "리투아니아",
        luxembourg: "룩셈부르크",
        macao_China: "중국 마카오",
        macedonia: "마케도니아",
        malaysia: "말레이시아",
        malta: "몰타",
        mexico: "멕시코",
        moldova: "몰도바",
        monaco: "모나코",
        mongolia: "몽골",
        montenegro: "몬테네그로",
        morocco: "모로코",
        myanmar: "미얀마",
        netherlands: "네덜란드",
        new_Zealand: "뉴질랜드",
        nepal: "네팔",
        nigeria: "나이지리아",
        norway: "노르웨이",
        oman: "오만",
        palestine: "팔레스타인",
        panama: "파나마",
        paraguay: "파라과이",
        peru: "페루",
        philippines: "필리핀",
        poland: "폴란드",
        portugal: "포르투갈",
        puerto_Rico: "푸에르토리코",
        qatar: "카타르",
        romania: "루마니아",
        russia: "러시아",
        republic_of_Trinidad_and_Tobago: "트리니다드 토바고",
        rwanda: "르완다",
        saudi_Arabia: "사우디아라비아",
        serbia: "세르비아",
        singapore: "싱가포르",
        slovakia: "슬로바키아",
        slovenia: "슬로베니아",
        south_Africa: "남아프리카",
        spain: "스페인",
        sri_Lanka: "스리랑카",
        sweden: "스웨덴",
        switzerland: "스위스",
        taiwan_China: "대만",
        tajikistan: "타지키스탄",
        tanzania: "탄자니아",
        thailand: "태국",
        turkey: "터키",
        turkmenistan: "투르크메니스탄",
        ukraine: "영국",
        united_Arab_Emirates: "미국",
        united_Kingdom: "우즈베키스탄",
        united_States: "베네수엘라",
        uzbekistan: "베트남",
        venezuela: "아프가니스탄",
        vietnam: "베트남",
        afghanistan: "아프가니스탄",
        angola: "앙골라",
        azerbaijan: "아제르바이잔",
        bangladesh: "방글라데시",
        belarus: "벨라루스",
        belize: "벨리즈",
        benin: "베냉",
        bhutan: "부탄",
        bolivia: "볼리비아",
        botswana: "보츠와나",
        british_Virgin_Islands: "영국령 버진 아일랜드",
        burkina_Faso: "부르키나파소",
        burundi: "부룬디",
        cape_Verde: "카보베르데",
        cayman_Islands: "케이맨 제도",
        central_African_Republic: "중앙아프리카",
        chad: "차드",
        comoros: "코모로",
        the_Republic_of_the_Congo: "콩고 (부)",
        democratic_Republic_of_the_Congo: "콩고 (김)",
        djibouti: "지부티",
        ecuador: "에콰도르",
        el_Salvador: "엘살바도르",
        equatorial_Guinea: "적도 기니",
        eritrea: "에리트레아",
        fiji: "피지",
        gabon: "가봉",
        gambia: "감비아",
        greenland: "그린란드",
        guatemala: "과테말라",
        guinea: "기니",
        haiti: "아이티",
        isle_of_Man: "맨 섬",
        cote_d_Ivoire: "코트디부아르",
        jamaica: "자메이카",
        jordan: "요르단",
        lebanon: "레바논",
        lesotho: "레소토",
        liberia: "라이베리아",
        libya: "리비아",
        madagascar: "마다가스카르",
        malawi: "말라위",
        maldives: "몰디브",
        mali: "말리",
        mauritania: "모리타니",
        mauritius: "모리셔스",
        mozambique: "모잠비크",
        namibia: "나미비아",
        nicaragua: "니카라과",
        republic_of_Niger: "니제르",
        north_Korea: "조선",
        reunion: "레위니옹",
        san_Marino: "산마리노",
        senegal: "세네갈",
        sierra_Leone: "시에라리온",
        somalia: "소말리아",
        sudan: "수단",
        suriname: "수리남",
        eswatini: "스와질란드",
        syria: "시리아",
        togo: "토고",
        tonga: "통가",
        tunisia: "튀니지",
        united_States_Virgin_Islands: "미국령 버진 아일랜드",
        uganda: "우간다",
        uruguay: "우루과이",
        vatican: "바티칸",
        yemen: "예멘",
        yugoslavia: "유고슬라비아",
        zambia: "잠비아",
        zimbabwe: "짐바브웨",
        china: "중국",
      }
};
