export default {
    addNew: {
        recharge: "Please contact customer service to obtain" ,
        bank_type1: "Wallet",
        bank_type0: "Bank",
        phone_number: "PhoneNumber",
        realname: "Realname",
        id_number: "Id Number",
        bank_code: "Bank Code",
        bank_address: "Bank Address",
        bank_branch: "Bank Branch",
        bank_number: "Bank Number",
        bank_name: "Bank Name",
        add_bank: "Add Bank",
        my_wallet: "Mon portefeuille",
        add_wallet: "Ajouter un portefeuille",
        place_wallet_type: "Veuillez sélectionner un type de portefeuille",
        place_wallet_name: "Veuillez entrer un nom de portefeuille",
        place_wallet_address: "Veuillez entrer l'adresse du portefeuille",
        reference_annualization: "Référence Annualisée",
        state: "état des lieux",
        term: "Délais",
        depositState0: "En cours",
        depositState1: "À venir",
        label_amount: "Montant",
        place_amount: "Veuillez entrer le montant",
        estimated_income: "Revenu prévu",
        available_assets: "Actif disponible",
        cancel: "Annulations",
        all: "Complet",
        min_amount: "Montant minimum",
        daily: "Production quotidienne",
        waiting_time: "Temps d'attente",
        product_name: "Chlorure de diéthylammonium",
        buy_total: "Nombre total d'achats",
        earnings: "Gain cumulé",

        myMessage: "Message",
        place_card_back: "Cliquez pour télécharger l'image inverse de votre carte d'identité",
        side1: "gestion des commandes",
        side2: "modifier le mot de passe",
        side3: "langue",
        side4: "se déconnecter",
        product: "produit",
        direction: "Direction",
        cycle: "pendant la transaction",
        long_buy: "acheter à long terme",
        short_sell: "Short - Sell",
        open_time: "heure d'ouverture",
        close_time: "heure de clôture",
        open_price: "prix d'ouverture",
        close_price: "prix de clôture",
        number: "quantité",
        profit_loss: "gagner ou perdre",
        commission: "frais de traitement",
        account_type: "type de changement de compte",
        type1: "compte coin",
        type2: "compte en monnaie française",
        type3: "compte contractuel",
        type4: "compte d'options",
        type5: "compte financier",
        message1: "Veuillez saisir le montant",
        message2: "Solde insuffisant du compte",
        place_transPsd: "Veuillez saisir le mot de passe de la transaction",
        countpath: "Fin du compte à rebours passer automatiquement à la page de commande",
        nowpath: "Sauter maintenant",
    },
    loan: {
        walletLink: "Veuillez ouvrir cette Connexion dans votre portefeuille!",
        connectWallet: "Connecter le portefeuille",

        loan_state_4: "Échouer",
        loan_state_3: "Remboursé",
        loan_state_2: "Décaissé",
        loan_state_1: "En attente d'approbation",
        repay_state_2: "Remboursé",
        repay_state_1: "Non-remboursement",
        repay_create: "Durée du prêt",
        repay_state: "État des lieux",
        repay_times: "Phase",
        repay_time: "Période de remboursement",
        repay_money: "Remboursement total",
        repay_total: "Montant du prêt",
        repay_pay: "Dossiers de remboursement",
        repay: "Dossiers de prêts",
        loan_pic4: "Photo d'identité",
        loan_pic3: "Relevé bancaire",
        loan_pic2: "Preuve de revenu",
        loan_pic1: "Certificat de propriété",
        loan_pic: "Prêts (veillez à ce que les photos soient clairement visibles)",
        loan_pics: "Formulaire d'identification",
        loan_pics_view: "Cliquez pour voir",
        loan_repay_method_1: "Remboursement forfaitaire du capital et des intérêts" ,
        loan_repay: "Mode de remboursement",
        loan_apr: "Taux d'intérêt journalier",
        loan_time: "Cycle de prêt",
        loan_type_1: "Jour",
        loan_type_2: "Lune",
        loan_money: "Montant du prêt",
        loan: "Prêt étudiant",
    },
    foot: {
        nav1: "Accueil",
        nav2: "Cotations",
        nav3: "Monnaie",
        nav4: "contractuel",
        nav5: "Actifs",
    },
    account: {
        verifyPass: "Verify Password",
        verifySuccess: "Certifié par",
        verifyFailed: "Non certifié",
        login: "Connexion",
        register_email: "Inscription par e-mail",
        register_phone: "Inscription par téléphone",
        label_phone: "Numéro de téléphone",
        place_phone: "Veuillez entrer votre numéro de téléphone",
        label_eMail: "Adresse e-mail",
        place_eMail: "Veuillez entrer votre adresse e-mail",
        tip_eMail: "Veuillez entrer une adresse e-mail valide",
        label_password: "Mot de passe",
        place_password: "Veuillez entrer votre mot de passe",
        tip_password: "Veuillez entrer un mot de passe valide",
        no_account: "Pas encore de compte ?",
        register: "S'inscrire",
        send: "Envoyer le code de vérification",
        label_code: "Code de vérification",
        place_code: "Code de vérification",
        tip_code: "Veuillez entrer le code de vérification",
        label_invitation: "Code d'invitation",
        place_invitation: "Veuillez entrer le code d'invitation",
    },
    assets: {
        title: "Actifs",
        total_assets: "Total des actifs équivalents",
        recharge: "Dépôt",
        withdraw: "Retrait",
        available: "Disponible",
        freeze: "Bloqué",
        total: "Total",
        label_bank: "Devise",
        place_bank: "Veuillez choisir la devise",
        tip_bank: "Veuillez choisir la devise",
        label_wallet: "Portefeuille",
        place_wallet: "Veuillez choisir le portefeuille",
        tip_wallet: "Veuillez choisir le portefeuille",
        label_amount: "Montant",
        place_amount: "Veuillez entrer le montant",
        tip_amount: "Veuillez entrer un montant valide",
        label_password: "Mot de passe",
        place_password: "Veuillez entrer le mot de passe",
        tip_password: "Veuillez entrer un mot de passe valide",
        label_balance: "Solde USDT disponible",
        all: "Retirer tout",
        no_password: "Pour la sécurité de vos fonds, veuillez définir un",
        withdraw_psd: "mot de passe de retrait",
        type1: "Compte spot",
        type2: "Compte Fiat",
        type3: "Compte de contrat",
        type4: "Compte de contrat à grande vitesse",
        type5: "Compte de gestion d'actifs",
        convert: "Équivalent",
        now_currency: "Devise actuelle",
        link_type: "Type de chaîne",
        recharge_address: "Adresse de dépôt",
        copy: "Copier l'adresse",
        label_address: "Adresse du portefeuille",
        place_address: "Veuillez entrer l'adresse du portefeuille",
        submit_process: "Soumettre pour vérification",
        tip1: "Ne déposez pas d'autres actifs que l'USDT à cette adresse, sinon vos actifs seront irrécupérables.",
        tip2: "Le dépôt d'USDT est uniquement pris en charge par la méthode 'simple send'. Les dépôts effectués avec d'autres méthodes ne seront pas crédités pour le moment. Veuillez nous en excuser.",
        tip3: "Après avoir déposé à cette adresse, votre dépôt nécessitera la confirmation de l'ensemble du réseau. Les fonds seront crédités après 1 confirmation réseau et pourront être retirés après 6 confirmations réseau.",
        tip4: "Votre adresse de dépôt ne change pas fréquemment et peut être utilisée à plusieurs reprises. En cas de modification, nous essaierons de vous informer via des annonces sur le réseau ou par e-mail.",
        tip5: "Assurez-vous de la sécurité de votre ordinateur et de votre navigateur pour éviter toute altération ou divulgation de vos informations.",
        record_title: "Historique des Transactions",
        label_number: "Quantité",
        label_record: "Enregistrement",
        label_time: "Heure",
        label_price: "Prix",
        label_volume: "Volume",
        transfer_title: "Transfert",
        from: "De",
        to: "À",
        transfer_record: "Historique des Transferts",
        label_withAddress: "Adresse de Retrait",
        place_withAddress: "Veuillez d'abord définir votre adresse de retrait",
        deposit_title: "Staking & Mining",
        buy: "Acheter",
        sell: "Vendre",
        week: "Semaine",
        daily_dividend: "Dividende Quotidien",
        deposit_log: "Historique de Staking & Mining",
        userinfo_nav1: "Vérification d'Identité",
        userinfo_nav2: "Mes Liens de Partage",
        userinfo_nav3: "Mes Codes d'Autorisation",
        userinfo_nav4: "Centre de Sécurité",
        userinfo_nav5: "Méthodes de Paiement",
        userinfo_nav6: "Service en Ligne",
        userinfo_nav7: "À Propos de Nous",
        userinfo_nav8: "Télécharger l'Application",
        userinfo_nav9: "Centre d'Aide",
        logout: "Déconnexion",
        second: "Contrat à Durée Courte",
        second_title: "Contrat à Durée Courte",
        invite: "Inviter",
        legal: "Trading de Monnaie Fiat",
        ieo: "IEO",
        state0: "Tout",
        state1: "En Prévente",
        state2: "En Souscription",
        state3: "Terminé",
        history_record: "Historique",
        legal_tab1: "J'achète",
        legal_tab2: "Je vends",
        transType: "Type de Transaction",
        order_state: "État de la Commande",
        order_state1: "Non Terminé",
        order_state2: "Payé",
        order_state3: "Confirmation en Cours",
        order_state4: "En Litige",
        order_state5: "Annulé",
        order_state6: "Terminé",
        reset: "Réinitialiser",
        hand_number: "Nombre de Lots",
        long: "Acheter Long",
        short: "Vendre Court",
        confirm_order: "Confirmer la Commande",
        type: "Type",
        multiple_number: "Multiplicateur",
        now_entrust: "Ordres en Cours",
        all_entrust: "Tous les Ordres",
        history_entrust: "Historique des Ordres",
        turnover: "Volume d'Échanges",
        operate: "Opérer",
        delete: "Supprimer",
        order_details: "Détails de la Commande",
        orderDetails_tab1: "En Attente",
        orderDetails_tab2: "En Cours",
        orderDetails_tab3: "Fermée",
        orderDetails_tab4: "Annulée",
        risk_rate: "Taux de Risque",
        total_pl: "Profit/Perte Total",
        closing_position: "Fermer la Position",
        position_list: "Liste des Positions",
        order_record: "Historique des Ordres",
        profit_rate: "Taux de Profit",
        buy_long: "Acheter à la Hausse",
        buy_short: "Acheter à la Baisse",
        trans_mode: "Mode de Transaction",
        opening_quantity: "Quantité à l'Ouverture",
        opening_number: "Heure d'Ouverture",
        place_transMount: "Entrez le Montant de la Transaction",
        account_balance: "Solde du Compte",
        privacy_policy: "Politique de Confidentialité",
        contact_us: "Contactez-nous",
        feedback: "Retour d'Utilisateur",
        feedback_type: "Type de Retour",
        feedback_type1: "Retour sur Retrait",
        feedback_type2: "Retour sur Blockchain",
        label_title: "Titre",
        place_title: "Entrez le Titre",
        label_cont: "Contenu du Retour",
        place_cont: "Entrez le Contenu",
        reset_password: "Réinitialiser le Mot de Passe",
        personal_center: "Espace Personnel",
        to_authenticate: "Accéder à l'Authentification",
        authenticate_tip1: "· L'authentification permet d'augmenter la limite de retrait",
        authenticate_tip2: "· Vous devez passer par une authentification réelle pour effectuer des retraits en monnaie fiat",
        payment_method: "Méthode de Paiement",
        label_reaName: "Nom Réel",
        palce_reaName: "Nom Réel",
        label_openBank: "Banque Émettrice",
        palce_openBank: "Entrez le Nom de la Banque Émettrice",
        label_bankNumber: "Numéro de Compte Bancaire",
        place_bankNumber: "Entrez le Numéro de Compte Bancaire",
        lable_branchBank: "Agence Bancaire",
        place_branchBank: "Entrez le Nom de l'Agence Bancaire",
        label_IDnumber: "Numéro d'Identité",
        place_IDnumber: "Entrez le Numéro d'Identité",
        label_IDimg: "Photo d'Identité (Recto)",
        label_IDimg2: "Photo d'Identité (Verso)",
        tip_IDimg: "Veuillez télécharger la Photo d'Identité (Verso)",
        bankCard: "Carte Bancaire",
        details: "Détails",
        my_authorization_code: "Mon Code d'Autorisation",
        my_authorization_code_tip: "Mon Code d'Autorisation",
        login_password: "Mot de Passe de Connexion",
        trans_password: "Mot de Passe de Transaction",
        modify: "Modifier",
        my_promotional_code: "Mon Code Promotionnel",
        promotional_code: "Code Promotionnel",
        promotional_code_tip: "Scannez le code QR ci-dessus ou saisissez le code promotionnel pour vous inscrire",
        copy_link: "Copier le Lien d'Invitation",
        modify_trans_psd: "Modifier le Mot de Passe de Transaction",
        label_email_verify: "Vérification par E-mail",
        place_email_verify: "Entrez le Code de Vérification",
        send: "Envoyer",
        email_verify_tip: "Le code de vérification sera envoyé à votre adresse e-mail",
        label_bitTitle: "Paire de Trading",
        label_profit: "Profit",
    },
    currency: {
        position: "Position",
        drawer_title: "Trading de Crypto-monnaies",
        now: "Actuel",
        open: "Ouverture",
        volume: "Volume",
        height: "Plus Haut",
        low: "Plus Bas",
        buy: "Acheter Long",
        sell: "Vendre Court",
        name: "Nom",
        direction: "Direction",
        crm_order: "Confirmer la Commande",
        now_price: "Prix Actuel",
        type_time: "Délai de Livraison (Glissez à gauche pour un rendement plus élevé)",
        second: "Seconde",
        buy_amount: "Montant d'Achat",
        available: "Disponible",
        service_fee: "Frais de Service",
        min_number: "Minimum",
        errorTip1: "Montant Incorrect",
        errorTip2: "Solde Insuffisant",
        auto_jump: "Redirection Automatique vers l'Ordre après",
        keep: "Continuer la Commande",
        revenue: "Revenu Anticipé",
    },
    home: {
        quick: "Dépôt Rapide",
        support: "Support",
        nav1: "Partager",
        nav2: "Portefeuille",
        nav3: "Actualités",
        nav4: "Tutoriels",
        nav5: "Service Client",
        nav6: "Mining",
        nav7: "À Propos de Nous",
        nav8: "Nouvelle Souscription",
        increase: "Top des Hausses",
        volume: "Volume de Trading",
        table_name: "Nom",
        table_lastPrice: "Dernier Prix",
        table_increase: "Variation",
        table_volume: "Volume Total",
    },
    lockming: {
        profit: "Profit Récents (Journalier)",
        cycle: "Cycle de Gestion",
        limit: "Limite Par Transaction",
        dividend_time: "Heure de Distribution des Dividendes",
        every_day: "Tous les Jours",
        funds: "Fonds en Custodie",
        back: "Retour à l'Échéance",
        ransom: "Rachat Anticipé",
        label_amount: "Montant de la Mise en Gage",
        place_amount: "Entrez le Montant...",
        available: "Disponible",
        expect_income: "Revenu Anticipé",
        title: "Gestion Verrouillée Mining",
        tip: "Mining Constant pour des Gains Continus",
        label_today_income: "Revenu Anticipé Aujourd'hui",
        label_total_income: "Revenu Cumulé",
        label_order: "Commandes en Custodie",
        label_number: "Montant Par Transaction",
        daily_return: "Taux de Rendement Journalier",
        subscribe: "Souscrire",
        position: "Position",
        label_state: "État",
        subscribe_time: "Heure de Souscription",
        expiration_time: "Date d'Échéance",
        income: "Revenu",
        errorTip1: "Veuillez Entrer un Montant",
        tip_tit: "Êtes-vous sûr de vouloir Récupérer de manière Anticipée?",
    },
    market: {
        spots: "Spot",
        contract: "Contrat",
        currency_trans: "Trading de Crypto-monnaies",
        increase: "Top des Hausses",
        volume: "Volume de Trading",
        label_name: "Nom",
        label_lastPrice: "Dernier Prix",
        table_increase: "Variation",
        table_volume: "Volume Total",
    },
    my: {
        details: "Détails",
        help: "Centre d'Aide",
        information: "Informations",
        language: "Langue",
        password: {
            account_title: "Modifier le mot de passe",
            withdraw_title: "Modifier le mot de passe",
            label_oldPsd: "Ancien mot de passe",
            place_oldPsd: "Entrez l'ancien mot de passe",
            label_newPsd: "Nouveau mot de passe",
            place_newPsd: "Entrez le nouveau mot de passe",
            label_crmPsd: "Confirmez le mot de passe",
            place_crmPsd: "Confirmez le nouveau mot de passe",
            withdraw_tip: "Si vous n'avez pas encore défini de mot de passe de retrait, veuillez laisser ce champ vide"
        },
        address: {
            title: "Adresse du Portefeuille",
            account_number: "Numéro de Compte",
            add_btn: "Ajouter l'Adresse",
            label_type: "Sélectionnez le Type",
            place_type: "Veuillez Sélectionner le Type",
            label_number: "Numéro de Compte",
            place_number: "Entrez le Numéro de Compte"
        },
        history: {
            title: "Historique",
            recharge_record: "Historique des Dépôts",
            withdraw_record: "Historique des Retraits",
            recharge: "Dépôt",
            withdraw: "Retrait",
            number: "Quantité",
            currency: "Monnaie",
            state: "État",
            state0: "En Attente de Vérification",
            state1: "Terminé",
            state2: "Refusé"
        },
        identity: {
            title: "Certification",
            label_country: "Sélectionnez la Nationalité",
            place_country: "Veuillez Sélectionner la Nationalité",
            label_name: "Nom",
            place_name: "Entrez votre Nom",
            label_id: "Numéro d'Identification",
            place_id: "Entrez votre Numéro de Carte d'Identité/Permis de Conduire/Passeport"
        },
        my: {
            list1: "Certification",
            list2: "Gestion des Commandes",
            list3: "Historique des Transactions",
            list4: "Adresse du Portefeuille",
            list5: "Changer la Langue",
            list6: "Inviter des Amis",
            list7: "Service Client",
            list8: "Tutoriels",
            list9: "Modifier le Mot de Passe",
            logout: "Déconnexion",
        },
        order: {
            title: "Positions",
            direction: "Direction",
            sell_price: "Prix de Vente",
            number: "Quantité",
            type: "Heure de Livraison",
            buy_price: "Prix d'Achat",
            profit: "Bénéfice/Perte",
            sell_time: "Heure de Vente",
        },
        share: {
            title: "Inviter des Amis",
            save_ewm: "Enregistrer le Code QR",
            invite_link: "Lien d'Invitation",
            copy_address: "Copier l'Adresse",
            invite_code: "Code d'Invitation",
            copy_code: "Copier le Code",
            tip: "Inscrivez-vous en utilisant le code QR ou le lien ci-dessus",
        },
    },
    transaction: {
        spots: "Trading au Comptant",
        contract: "Contrat",
        currency_trans: "Trading de Cryptomonnaies",
        exchange: "Échange Instantané",
        sustainable: "Contrat Perpétuel",
        warehouse: "Contrat d'Entreposage",
        jump_lever: "Changer de Levier",
        sell: "Vendre",
        buy: "Acheter",
        available: "Solde Disponible",
        check_price: "Ordre Limité",
        market_price: "Ordre au Marché",
        number: "Quantité",
        max: "Max",
        bail: "Marge",
        buy_long: "Acheter Long",
        sell_short: "Vendre Court",
        last_price: "Dernier Prix",
        now_entrust: "Ordres en Cours",
        now_position: "Positions Actuelles",
        table_amount: "Montant du Retrait",
        is_reduce: "Liquidation Seulement",
        shi: "Oui",
        fou: "Non",
        position_number: "Taille de la Position",
        is_profit: "Réaliser un Bénéfice",
        wei: "Non Réalisé",
        open_price: "Prix d'Ouverture",
        mark_price: "Prix de Marquage",
        flat_price: "Prix de Liquidation",
        trim_lever: "Ajuster le Levier",
        flat_store: "Fermer la Position",
        trim_bail: "Ajuster la Marge",
        reduce_bail: "Réduire la Marge",
        now_bail: "Marge Actuelle de la Position",
        max_add: "Ajout Maximum Possible",
        refer_flat: "Prix de Liquidation de Référence Après Ajustement",
        expect_profit: "Bénéfice Prévu",
    },
    common: {
        confirm: "Confirmer",
        cancel: "Annuler",
        all: "Tout",
        close: "Fermer",
        copy_success: "Copié avec succès !",
        copy_error: "Échec de la copie !",
        place_input: "Veuillez saisir le contenu",
        more: "Plus",
        main: "Graphique Principal",
        sub: "Graphique Secondaire",
        success: "Succès !",
        cancel_tip: "Êtes-vous sûr de vouloir annuler ?",
        notice: "Avis",
        logout_tip: "Confirmez-vous la déconnexion ?",
        tip: "Conseil",
        day: "Jour",
        place_inner: "Veuillez saisir le contenu",
        fail_banner: "Solde Insuffisant",
        empty: "Aucune donnée disponible",
    },
    message: {
		cancel_order: "Voulez-vous annuler l'ordre ?",
        stop_out: "Voulez-vous forcer la liquidation ?",
	},
    params: {
        transType0: "Monnaie",
        transType1: "contractuel",
        directionType0: "Acheter",
        directionType1: "Vendre",
        lockStateType0: "En Cours",
        lockStateType1: "Terminé",
        lockStateType2: "Annulé",
	},
	city: {
        albania: "Albanie",
        algeria: "Algérie",
        argentina: "Argentine",
        armenia: "Arménie",
        australia: "Australie",
        pakistan: "Pakistan",
        austria: "Autriche",
        bahrain: "Bahreïn",
        belgium: "Belgique",
        bosnia_and_Herzegovina: "Bosnie-Herzégovine",
        brazil: "Brésil",
        brunei: "Brunéi",
        bulgaria: "Bulgarie",
        cambodia: "Cambodge",
        canada: "Canada",
        cameroon: "Cameroun",
        chile: "Chili",
        colombia: "Colombie",
        costa_Rica: "Costa Rica",
        croatia: "Croatie",
        cyprus: "Chypre",
        czech_Republic: "République Tchèque",
        denmark: "Danemark",
        dominican_Republic: "République Dominicaine",
        egypt: "Égypte",
        estonia: "Estonie",
        ethiopia: "Éthiopie",
        finland: "Finlande",
        france: "France",
        georgia: "Géorgie",
        germany: "Allemagne",
        ghana: "Ghana",
        greece: "Grèce",
        guyana: "Guyana",
        honduras: "Honduras",
        hong_Kong_China: "Hong Kong (Chine)",
        hungary: "Hongrie",
        iceland: "Islande",
        ireland: "Irlande",
        italy: "Italie",
        india: "Inde",
        indonesia: "Indonésie",
        israel: "Israël",
        iran: "Iran",
        iraq: "Irak",
        japan: "Japon",
        kazakstan: "Kazakhstan",
        kenya: "Kenya",
        korea: "Corée",
        kuwait: "Koweït",
        kyrgyzstan: "Kirghizistan",
        laos: "Laos",
        latvia: "Lettonie",
        lithuania: "Lituanie",
        luxembourg: "Luxembourg",
        macao_China: "Macao (Chine)",
        macedonia: "Macédoine",
        malaysia: "Malaisie",
        malta: "Malte",
        mexico: "Mexique",
        moldova: "Moldavie",
        monaco: "Monaco",
        mongolia: "Mongolie",
        montenegro: "Monténégro",
        morocco: "Maroc",
        myanmar: "Myanmar",
        netherlands: "Pays-Bas",
        new_Zealand: "Nouvelle-Zélande",
        nepal: "Népal",
        nigeria: "Nigeria",
        norway: "Norvège",
        oman: "Oman",
        palestine: "Palestine",
        panama: "Panama",
        paraguay: "Paraguay",
        peru: "Pérou",
        philippines: "Philippines",
        poland: "Pologne",
        portugal: "Portugal",
        puerto_Rico: "Porto Rico",
        qatar: "Qatar",
        romania: "Roumanie",
        russia: "Russie",
        republic_of_Trinidad_and_Tobago: "République de Trinité-et-Tobago",
        rwanda: "Rwanda",
        saudi_Arabia: "Arabie Saoudite",
        serbia: "Serbie",
        singapore: "Singapour",
        slovakia: "Slovaquie",
        slovenia: "Slovénie",
        south_Africa: "Afrique du Sud",
        spain: "Espagne",
        sri_Lanka: "Sri Lanka",
        sweden: "Suède",
        switzerland: "Suisse",
        taiwan_China: "Taïwan (Chine)",
        tajikistan: "Tadjikistan",
        tanzania: "Tanzanie",
        thailand: "Thaïlande",
        turkey: "Turquie",
        turkmenistan: "Turkménistan",
        ukraine: "Ukraine",
        united_Arab_Emirates: "Émirats Arabes Unis",
        united_Kingdom: "Royaume-Uni",
        united_States: "États-Unis",
        uzbekistan: "Ouzbékistan",
        venezuela: "Venezuela",
        vietnam: "Vietnam",
        afghanistan: "Afghanistan",
        angola: "Angola",
        azerbaijan: "Azerbaïdjan",
        bangladesh: "Bangladesh",
        belarus: "Biélorussie",
        belize: "Belize",
        benin: "Bénin",
        bhutan: "Bhoutan",
        bolivia: "Bolivie",
        botswana: "Botswana",
        british_Virgin_Islands: "Îles Vierges Britanniques",
        burkina_Faso: "Burkina Faso",
        burundi: "Burundi",
        cape_Verde: "Cap-Vert",
        cayman_Islands: "Îles Caïmans",
        central_African_Republic: "République Centrafricaine",
        chad: "Tchad",
        comoros: "Comores",
        the_Republic_of_the_Congo: "République du Congo",
        democratic_Republic_of_the_Congo: "République Démocratique du Congo",
        djibouti: "Djibouti",
        ecuador: "Équateur",
        el_Salvador: "Salvador",
        equatorial_Guinea: "Guinée Équatoriale",
        eritrea: "Érythrée",
        fiji: "Fidji",
        gabon: "Gabon",
        gambia: "Gambie",
        greenland: "Groenland",
        guatemala: "Guatemala",
        guinea: "Guinée",
        haiti: "Haïti",
        isle_of_Man: "Île de Man",
        cote_d_Ivoire: "Côte d'Ivoire",
        jamaica: "Jamaïque",
        jordan: "Jordanie",
        lebanon: "Liban",
        lesotho: "Lesotho",
        liberia: "Libéria",
        libya: "Libye",
        madagascar: "Madagascar",
        malawi: "Malawi",
        maldives: "Maldives",
        mali: "Mali",
        mauritania: "Mauritanie",
        mauritius: "Île Maurice",
        mozambique: "Mozambique",
        namibia: "Namibie",
        nicaragua: "Nicaragua",
        republic_of_Niger: "République du Niger",
        north_Korea: "Corée du Nord",
        reunion: "Réunion",
        san_Marino: "Saint-Marin",
        senegal: "Sénégal",
        sierra_Leone: "Sierra Leone",
        somalia: "Somalie",
        sudan: "Soudan",
        suriname: "Suriname",
        eswatini: "Eswatini",
        syria: "Syrie",
        togo: "Togo",
        tonga: "Tonga",
        tunisia: "Tunisie",
        united_States_Virgin_Islands: "Îles Vierges des États-Unis",
        uganda: "Ouganda",
        uruguay: "Uruguay",
        vatican: "Vatican",
        yemen: "Yémen",
        yugoslavia: "Yougoslavie",
        zambia: "Zambie",
        zimbabwe: "Zimbabwe",
        china: "Chine",
    }
};
