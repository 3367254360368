export default {
    addNew: {
        recharge: "Please contact customer service to obtain" ,
        bank_type1: "Wallet",
        bank_type0: "Bank",
        phone_number: "PhoneNumber",
        realname: "Realname",
        id_number: "Id Number",
        bank_code: "Bank Code",
        bank_address: "Bank Address",
        bank_branch: "Bank Branch",
        bank_number: "Bank Number",
        bank_name: "Bank Name",
        add_bank: "Add Bank",
        my_wallet: "Minha carteira",
        add_wallet: "Engadir carteira",
        place_wallet_type: "Por favor, escolha o tipo de carteira",
        place_wallet_name: "Por favor, introduza o nome da carteira",
        place_wallet_address: "Por favor, introduz o endereço da carteira",
        reference_annualization: "Referência Anualizada",
        state: "Estado das coisas",
        term: "Prazos",
        depositState0: "Em andamento.",
        depositState1: "Aguarde ansiosamente",
        label_amount: "Soma de dinheiro",
        place_amount: "Digite o valor",
        estimated_income: "Renda projetada",
        available_assets: "Ativo disponível",
        cancel: "Cancelamentos",
        all: "Completo",
        min_amount: "Valor mínimo",
        daily: "Produção diária",
        waiting_time: "Tempo de espera",
        product_name: "Cloreto de dietilamônio",
        buy_total: "Número total de compras",
        earnings: "Ganho acumulado",

        myMessage: "Message",
        place_card_back: "Clique para carregar uma foto do verso do seu cartão de identificação",
        side1: "Gestão de encomendas",
        side2: "Alterar senha",
        side3: "Linguagem",
        side4: "Log out of login",
        product: "Produto",
        direction: "Direcção",
        cycle: "Período de transacção",
        long_buy: "Buy long",
        short_sell: "Short sell",
        open_time: "Opening time",
        close_time: "Closing Time",
        open_price: "Preço de abertura",
        close_price: "Preço de encerramento",
        number: "Quantidade",
        profit_loss: "Lucros e perdas",
        commission: "Taxa de tratamento",
        account_type: "Tipo de alteração de conta",
        type1: "Conta de moeda",
        type2: "Conta de moeda legal",
        type3: "Conta contratual",
        type4: "Conta de opção",
        type5: "Conta de gestão de património",
        message1: "Introduza o montante",
        message2: "Saldo insuficiente da conta",
        place_transPsd: "Por favor insira a senha da transação",
        countpath: "Saltar automaticamente para a página do pedido após o término da contagem regressiva",
        nowpath: "Salte imediatamente",
    },
    loan: {
        walletLink: "Por favor, abra esta ligação na sua carteira！",
        connectWallet: "Ligar à carteira",

        loan_state_4: "Falhar ",
        loan_state_3: "Reembolsado",
        loan_state_2: "Desembolsado",
        loan_state_1: "A aguardar aprovação",
        repay_state_2: "Reembolsado",
        repay_state_1: "Não reembolso",
        repay_create: "Período do empréstimo",
        repay_state: "Estado das coisas",
        repay_times: "Fase",
        repay_time: "Período de reembolso",
        repay_money: "Reembolso total",
        repay_total: "Montante do empréstimo",
        repay_pay: "Registos de reembolso",
        repay: "Registos de empréstimos",
        loan_pic4: "Identificação com fotografia",
        loan_pic3: "Extrato bancário",
        loan_pic2: "Prova de rendimentos",
        loan_pic1: "Certificado de propriedade",
        loan_pic: "Empréstimos de crédito (certifique-se de que as imagens são claramente visíveis)",
        loan_pics: "Formulário de identificação",
        loan_pics_view: "Clique para ver",
        loan_repay_method_1: "Reembolso de capital e juros de uma só vez" ,
        loan_repay: "Método de reembolso",
        loan_apr: "Taxa de juro diária",
        loan_time: "Ciclo do empréstimo",
        loan_type_1: "Dia",
        loan_type_2: "Lua",
        loan_money: "Montante do empréstimo",
        loan: "Empréstimo estudantil",
    },
    foot: {
        nav1: "Início",
        nav2: "Mercado",
        nav3: "Spot",
        nav4: "Contratos",
        nav5: "Ativos",
    },
    account: {
        verifyPass: "Verify Password",
        verifySuccess: "Certificado",
        verifyFailed: "Não certificado",
        login: "Entrar",
        register_email: "Registro por E-mail",
        register_phone: "Registro por Celular",
        label_phone: "Número de Celular",
        place_phone: "Digite o seu número de celular",
        label_eMail: "E-mail",
        place_eMail: "Digite o seu endereço de e-mail",
        tip_eMail: "Por favor, insira um endereço de e-mail válido",
        label_password: "Senha",
        place_password: "Digite a senha",
        tip_password: "Por favor, insira uma senha válida",
        no_account: "Ainda não tem uma conta?",
        register: "Registrar",
        send: "Enviar Código de Verificação",
        label_code: "Código de Verificação",
        place_code: "Código de Verificação",
        tip_code: "Por favor, insira o código de verificação",
        label_invitation: "Código de Convite",
        place_invitation: "Digite o código de convite",
    },
    assets: {
        title: "Ativos",
        total_assets: "Ativos Totais",
        recharge: "Depositar",
        withdraw: "Retirar",
        available: "Disponível",
        freeze: "Congelado",
        total: "Total",
        label_bank: "Moeda",
        place_bank: "Selecione a moeda",
        tip_bank: "Selecione a moeda",
        label_wallet: "Carteira",
        place_wallet: "Selecione a carteira",
        tip_wallet: "Selecione a carteira",
        label_amount: "Quantidade",
        place_amount: "Insira a quantidade",
        tip_amount: "Insira a quantidade correta",
        label_password: "Senha",
        place_password: "Insira a senha",
        tip_password: "Insira a senha correta",
        label_balance: "USDT Disponível",
        all: "Retirar Tudo",
        no_password: "Para a segurança dos seus fundos, por favor configure",
        withdraw_psd: "Senha de Retirada",
        type1: "Conta Spot",
        type2: "Conta Fiat",
        type3: "Conta de Contrato",
        type4: "Conta de Contrato Rápido",
        type5: "Conta de Financiamento",
        convert: "Converter",
        now_currency: "Moeda Atual",
        link_type: "Tipo de Chain",
        recharge_address: "Endereço de Depósito",
        copy: "Copiar Endereço",
        palce_amount: "Insira a quantidade de depósito",
        label_address: "Endereço da Carteira",
        palce_address: "Insira o endereço da carteira",
        submit_process: "Enviar para Revisão",
        tip1: "Não deposite qualquer ativo que não seja USDT no endereço acima, pois os ativos não podem ser recuperados.",
        tip2: "Os depósitos de USDT só são suportados pelo método de envio simples (simple send). Depósitos por outros métodos temporariamente não podem ser creditados. Pedimos a sua compreensão.",
        tip3: "Após depositar no endereço acima, é necessário a confirmação de toda a rede. Os depósitos serão creditados após 1 confirmação de rede, e saques serão permitidos após 6 confirmações de rede.",
        tip4: "Seu endereço de depósito não muda frequentemente e você pode depositar repetidamente. Caso haja alguma mudança, tentaremos notificá-lo por meio de anúncios na rede ou por e-mail.",
        tip5: "Certifique-se de que seu computador e navegador estejam seguros para evitar alterações ou vazamento de informações.",
        record_title: "Histórico de Transações",
        label_number: "Quantidade",
        label_record: "Registro",
        label_time: "Hora",
        label_price: "Preço",
        label_volume: "Volume",
        transfer_title: "Transferir",
        from: "De",
        to: "Para",
        transfer_record: "Registro de Transferência",
        label_withAddress: "Endereço de Retirada",
        place_withAddress: "Por favor, configure seu endereço de retirada primeiro",
        deposit_title: "Mineração de Staking",
        buy: "Comprar",
        sell: "Vender",
        week: "Período",
        daily_dividend: "Dividendo Diário",
        deposit_log: "Registro de Mineração de Staking",
        userinfo_nav1: "Autenticação de Identidade",
        userinfo_nav2: "Meu Link de Compartilhamento",
        userinfo_nav3: "Meu Código de Autorização",
        userinfo_nav4: "Centro de Segurança",
        userinfo_nav5: "Métodos de Recebimento",
        userinfo_nav6: "Suporte Online",
        userinfo_nav7: "Sobre Nós",
        userinfo_nav8: "Baixar o Aplicativo",
        userinfo_nav9: "Central de Ajuda",
        logout: "Sair",
        second: "Contrato Perpétuo",
        second_title: "Contrato de Velocidade",
        invite: "Convidar",
        legal: "Negociação em Moeda Fiat",
        ieo: "IEO",
        state0: "Todos",
        state1: "Pré-venda",
        state2: "Em Subscrição",
        state3: "Concluído",
        history_record: "Histórico de Transações",
        legal_tab1: "Comprar",
        legal_tab2: "Vender",
        transType: "Tipo de Transação",
        order_state: "Estado do Pedido",
        order_state1: "Não Concluído",
        order_state2: "Pago",
        order_state3: "Confirmação Adiada",
        order_state4: "Em Disputa",
        order_state5: "Cancelado",
        order_state6: "Concluído",
        reset: "Redefinir",
        hand_number: "Número de Lotes Negociados",
        long: "Comprar (Long)",
        short: "Vender (Short)",
        confirm_order: "Confirmar Pedido",
        type: "Tipo",
        multiple_number: "Multiplicador",
        now_entrust: "Ordens em Aberto Atuais",
        all_entrust: "Todas as Ordens em Aberto",
        history_entrust: "Histórico de Ordens em Aberto",
        turnover: "Volume de Negociação",
        operate: "Operação",
        delete: "Excluir",
        order_details: "Detalhes do Pedido",
        orderDetails_tab1: "Em Aberto",
        orderDetails_tab2: "Em Negociação",
        orderDetails_tab3: "Fechado",
        orderDetails_tab4: "Cancelado",
        risk_rate: "Taxa de Risco",
        total_pl: "Lucro/Prejuízo Total",
        closing_position: "Fechar Todas as Posições",
        position_list: "Lista de Posições",
        order_record: "Registro de Pedidos",
        profit_rate: "Taxa de Lucro",
        buy_long: "Comprar (Long)",
        buy_short: "Comprar (Short)",
        trans_mode: "Modo de Negociação",
        opening_quantity: "Quantidade na Abertura",
        opening_number: "Hora de Abertura",
        place_transMount: "Insira o Montante da Negociação",
        account_balance: "Saldo da Conta",
        privacy_policy: "Política de Privacidade",
        contact_us: "Entre em Contato Conosco",
        feedback: "Feedback do Usuário",
        feedback_type: "Tipo de Feedback",
        feedback_type1: "Relacionado a Saques",
        feedback_type2: "Relacionado à Blockchain",
        label_title: "Título",
        place_title: "Insira o Título",
        label_cont: "Conteúdo do Feedback",
        place_cont: "Insira o Conteúdo",
        reset_password: "Redefinir Senha",
        personal_center: "Centro Pessoal",
        to_authenticate: "Fazer Autenticação",
        authenticate_tip1: "· A autenticação aumentará o limite de saque",
        authenticate_tip2: "· É necessário autenticação de identidade para saques e negociações em moeda fiat",
        payment_method: "Método de Recebimento",
        label_reaName: "Nome Real",
        palce_reaName: "Nome Real",
        label_openBank: "Banco de Abertura",
        palce_openBank: "Insira o Nome do Banco de Abertura",
        label_bankNumber: "Número da Conta Bancária",
        place_bankNumber: "Insira o Número da Conta Bancária",
        lable_branchBank: "Agência Bancária de Abertura",
        place_branchBank: "Insira o Nome da Agência Bancária de Abertura",
        label_IDnumber: "Número de Identificação",
        place_IDnumber: "Insira o Número de Identificação",
        label_IDimg: "Foto do Documento de Identidade (Frente)",
        label_IDimg2: "Foto do Documento de Identidade (Verso)",
        tip_IDimg: "Faça o upload da foto do verso do seu documento de identidade",
        bankCard: "Cartão Bancário",
        details: "Detalhes",
        my_authorization_code: "Meu Código de Autorização",
        my_authorization_code_tip: "Meu Código de Autorização",
        login_password: "Senha de Login",
        trans_password: "Senha de Negociação",
        modify: "Modificar",
        my_promotional_code: "Meu Código Promocional",
        promotional_code: "Código Promocional",
        promotional_code_tip: "Escanear o código QR acima ou inserir o código promocional para confirmar o registro",
        copy_link: "Copiar Link de Convite",
        modify_trans_psd: "Modificar Senha de Negociação",
        label_email_verify: "Verificação de E-mail",
        place_email_verify: "Insira o Código de Verificação",
        send: "Enviar",
        email_verify_tip: "O código de verificação será enviado para o seu e-mail",
        label_bitTitle: "Par de Negociação",
        label_profit: "Lucro",
    },
    currency: {
        position: "Posição",
        drawer_title: "Negociação de Moedas",
        now: "Atual",
        open: "Abertura",
        volume: "Volume de Negociação",
        height: "Máxima",
        low: "Mínima",
        buy: "Comprar (Long)",
        sell: "Vender (Short)",
        name: "Nome",
        direction: "Direção",
        crm_order: "Confirmar Ordem",
        now_price: "Preço Atual",
        type_time: "Tempo de Entrega (Deslize à Esquerda para Maior Lucro)",
        second: "Segundos",
        buy_amount: "Montante da Compra",
        available: "Disponível",
        service_fee: "Taxa de Serviço",
        min_number: "Mínimo",
        errorTip1: "Erro no Montante Digitado",
        errorTip2: "Saldo Disponível Insuficiente",
        auto_jump: " segundos até a Redirecionamento Automático para Pedidos",
        keep: "Continuar Pedido",
        revenue: "Lucro Esperado",
    },
    home: {
        quick: "Depósito Rápido",
        support: "Suporte",
        nav1: "Compartilhar",
        nav2: "Carteira",
        nav3: "Notícias",
        nav4: "Tutoriais",
        nav5: "Suporte ao Cliente",
        nav6: "Mineração",
        nav7: "Sobre Nós",
        nav8: "Comprar Nova Moeda",
        increase: "Lista de Maiores Ganhadores",
        volume: "Volume de Negociação",
        table_name: "Nome",
        table_lastPrice: "Último Preço",
        table_increase: "Variação",
        table_volume: "Volume de Negociação",
    },
    lockming: {
        profit: "Lucro Atual (Lucro Diário)",
        cycle: "Período de Investimento",
        limit: "Limite por Transação",
        dividend_time: "Horário de Dividendos",
        every_day: "Diariamente",
        funds: "Fundos em Custódia",
        back: "Devolução ao Vencimento",
        ransom: "Resgatar Antecipadamente",
        label_amount: "Montante do Depósito",
        place_amount: "Digite...",
        available: "Disponível",
        expect_income: "Lucro Esperado",
        title: "Mineração com Bloqueio",
        tip: "Minere sem Parar",
        label_today_income: "Lucro Esperado Hoje",
        label_total_income: "Lucro Total",
        label_order: "Ordens em Custódia",
        label_number: "Montante por Ordem",
        daily_return: "Taxa de Lucro Diária",
        subscribe: "Subscrever",
        position: "Posição",
        label_state: "Estado",
        subscribe_time: "Hora de Subscrição",
        expiration_time: "Data de Vencimento",
        income: "Lucro",
        errorTip1: "Por favor, insira um montante",
        tip_tit: "Tem a certeza de que deseja resgatar antecipadamente?",
    },
    market: {
        spots: "Spot",
        contract: "Contrato",
        currency_trans: "Negociação de Moedas",
        increase: "Lista de Maiores Ganhadores",
        volume: "Volume de Negociação",
        label_name: "Nome",
        label_lastPrice: "Último Preço",
        table_increase: "Variação",
        table_volume: "Volume de Negociação",
    },
    my: {
        details: "Detalhes",
        help: "Central de Ajuda",
        information: "Notícias",
        language: "Trocar Idioma",
        password: {
            account_title: "Alterar Senha",
            withdraw_title: "Alterar Senha",
            label_oldPsd: "Senha Antiga",
            place_oldPsd: "Insira a Senha Antiga",
            label_newPsd: "Nova Senha",
            place_newPsd: "Insira a Nova Senha",
            label_crmPsd: "Confirmar Senha",
            place_crmPsd: "Digite a Nova Senha Novamente",
            withdraw_tip: "Se você não definiu uma senha de saque, deixe em branco",
        },
        address: {
            title: "Endereço da Carteira",
            account_number: "Número da Conta",
            add_btn: "Adicionar Endereço",
            label_type: "Selecione o Tipo",
            place_type: "Selecione o Tipo",
            label_number: "Número da Conta",
            place_number: "Digite o Número da Conta",
        },
        history: {
            title: "Histórico",
            recharge_record: "Histórico de Depósitos",
            withdraw_record: "Histórico de Saques",
            recharge: "Depósito",
            withdraw: "Saque",
            number: "Quantidade",
            currency: "Moeda",
            state: "Estado",
            state0: "Pendente de Aprovação",
            state1: "Concluído",
            state2: "Recusado",
        },
        identity: {
            title: "Identificação",
            label_country: "Selecione o País",
            place_country: "Selecione o País",
            label_name: "Nome",
            place_name: "Digite o seu Nome",
            label_id: "Número de Documento",
            place_id: "Digite o seu Número de Documento (Carteira de Identidade, Carteira de Motorista ou Passaporte)",
        },
        my: {
            list1: "Identificação",
            list2: "Gerenciamento de Ordens",
            list3: "Histórico Financeiro",
            list4: "Endereço da Carteira",
            list5: "Trocar Idioma",
            list6: "Convidar Amigos",
            list7: "Suporte ao Cliente",
            list8: "Tutoriais",
            list9: "Alterar Senha",
            logout: "Sair",
        },
        order: {
            title: "Ordens em Custódia",
            direction: "Direção",
            sell_price: "Preço de Venda",
            number: "Quantidade",
            type: "Tempo de Entrega",
            buy_price: "Preço de Compra",
            profit: "Lucro/Prejuízo",
            sell_time: "Hora de Venda",
        },
        share: {
            title: "Convidar Amigos",
            save_ewm: "Salvar Código QR",
            invite_link: "Link de Convite",
            copy_address: "Copiar Endereço",
            invite_code: "Código de Convite",
            copy_code: "Copiar Código",
            tip: "Registre-se usando o código QR ou link acima",
        },
    },
    transaction: {
        spots: "À Vista",
        contract: "Contrato",
        currency_trans: "Negociação de Moeda",
        exchange: "Troca Rápida",
        sustainable: "Perpétuo",
        warehouse: "Por Contrato",
        jump_lever: "Saltar Alavancagem",
        sell: "Vender",
        buy: "Comprar",
        available: "Disponível",
        check_price: "Preço Limitado",
        market_price: "Preço de Mercado",
        number: "Quantidade",
        max: "Máximo",
        bail: "Margem",
        buy_long: "Comprar Longo",
        sell_short: "Vender Curto",
        last_price: "Último Preço",
        now_entrust: "Posições Anteriores",
        now_position: "Posição Atual",
        table_amount: "Montante de Retirada",
        is_reduce: "Apenas Reduzir Posição",
        shi: "Sim",
        fou: "Não",
        position_number: "Número de Posições",
        is_profit: "Realizar Lucro",
        wei: "Não Realizado",
        open_price: "Preço de Abertura",
        mark_price: "Preço de Marcação",
        flat_price: "Preço de Liquidação",
        trim_lever: "Ajustar Alavancagem",
        flat_store: "Fechar Posição",
        trim_bail: "Ajustar Margem",
        reduce_bail: "Reduzir Margem",
        now_bail: "Margem da Posição Atual",
        max_add: "Máximo de Acréscimo",
        refer_flat: "Preço de Liquidação de Referência Após Ajuste",
        expect_profit: "Lucro Esperado",
    },
    common: {
        confirm: "Confirmar",
        Cancel: "Cancelar",
        all: "Tudo",
        close: "Fechar",
        copy_success: "Cópia Bem-sucedida!",
        copy_error: "Falha na Cópia!",
        place_input: "Por favor, insira conteúdo",
        more: "Mais",
        main: "Gráfico Principal",
        sub: "Gráfico Secundário",
        success: "Sucesso!",
        cancel_tip: "Tem certeza de que deseja cancelar?",
        notice: "Aviso",
        logout_tip: "Confirmação de saída?",
        tip: "Dica",
        day: "Dia",
        place_inner: "Por favor, insira conteúdo",
        fail_banner: "Saldo Insuficiente",
        empty: "Sem Dados",
    },
    message: {
		cancel_order: "Deseja cancelar a ordem?",
        stop_out: "Deseja liquidação forçada?",
	},
    params: {
        transType0: "Moeda a Moeda",
        transType1: "Contrato",
        directionType0: "Comprar",
        directionType1: "Vender",
        lockStateType0: "Em Execução",
        lockStateType1: "Concluído",
        lockStateType2: "Cancelado",
	},
	city: {
        albania: "Albânia",
        algeria: "Argélia",
        argentina: "Argentina",
        armenia: "Armênia",
        australia: "Austrália",
        pakistan: "Paquistão",
        austria: "Áustria",
        bahrain: "Bahrein",
        belgium: "Bélgica",
        bosnia_and_Herzegovina: "Bósnia e Herzegovina",
        brazil: "Brasil",
        brunei: "Brunei",
        bulgaria: "Bulgária",
        cambodia: "Camboja",
        canada: "Canadá",
        cameroon: "Camarões",
        chile: "Chile",
        colombia: "Colômbia",
        costa_Rica: "Costa Rica",
        croatia: "Croácia",
        cyprus: "Chipre",
        czech_Republic: "República Tcheca",
        denmark: "Dinamarca",
        dominican_Republic: "República Dominicana",
        egypt: "Egito",
        estonia: "Estônia",
        ethiopia: "Etiópia",
        finland: "Finlândia",
        france: "França",
        georgia: "Geórgia",
        germany: "Alemanha",
        ghana: "Gana",
        greece: "Grécia",
        guyana: "Guiana",
        honduras: "Honduras",
        hong_Kong_China: "Hong Kong, China",
        hungary: "Hungria",
        iceland: "Islândia",
        ireland: "Irlanda",
        italy: "Itália",
        india: "Índia",
        indonesia: "Indonésia",
        israel: "Israel",
        iran: "Irã",
        iraq: "Iraque",
        japan: "Japão",
        kazakstan: "Cazaquistão",
        kenya: "Quênia",
        korea: "Coreia",
        kuwait: "Kuwait",
        kyrgyzstan: "Quirguistão",
        laos: "Laos",
        latvia: "Letônia",
        lithuania: "Lituânia",
        luxembourg: "Luxemburgo",
        macao_China: "Macao, China",
        macedonia: "Macedônia",
        malaysia: "Malásia",
        malta: "Malta",
        mexico: "México",
        moldova: "Moldávia",
        monaco: "Mônaco",
        mongolia: "Mongólia",
        montenegro: "Montenegro",
        morocco: "Marrocos",
        myanmar: "Mianmar",
        netherlands: "Holanda",
        new_Zealand: "Nova Zelândia",
        nepal: "Nepal",
        nigeria: "Nigéria",
        norway: "Noruega",
        oman: "Omã",
        palestine: "Palestina",
        panama: "Panamá",
        paraguay: "Paraguai",
        peru: "Peru",
        philippines: "Filipinas",
        poland: "Polônia",
        portugal: "Portugal",
        puerto_Rico: "Porto Rico",
        qatar: "Catar",
        romania: "Romênia",
        russia: "Rússia",
        republic_of_Trinidad_and_Tobago: "República de Trinidad e Tobago",
        rwanda: "Ruanda",
        saudi_Arabia: "Arábia Saudita",
        serbia: "Sérvia",
        singapore: "Cingapura",
        slovakia: "Eslováquia",
        slovenia: "Eslovênia",
        south_Africa: "África do Sul",
        spain: "Espanha",
        sri_Lanka: "Sri Lanka",
        sweden: "Suécia",
        switzerland: "Suíça",
        taiwan_China: "Taiwan, China",
        tajikistan: "Tajiquistão",
        tanzania: "Tanzânia",
        thailand: "Tailândia",
        turkey: "Turquia",
        turkmenistan: "Turcomenistão",
        ukraine: "Ucrânia",
        united_Arab_Emirates: "Emirados Árabes Unidos",
        united_Kingdom: "Reino Unido",
        united_States: "Estados Unidos",
        uzbekistan: "Uzbequistão",
        venezuela: "Venezuela",
        vietnam: "Vietnã",
        afghanistan: "Afeganistão",
        angola: "Angola",
        azerbaijan: "Azerbaijão",
        bangladesh: "Bangladesh",
        belarus: "Bielorrússia",
        belize: "Belize",
        benin: "Benin",
        bhutan: "Butão",
        bolivia: "Bolívia",
        botswana: "Botsuana",
        british_Virgin_Islands: "Ilhas Virgens Britânicas",
        burkina_Faso: "Burkina Faso",
        burundi: "Burundi",
        cape_Verde: "Cabo Verde",
        cayman_Islands: "Ilhas Cayman",
        central_African_Republic: "República Centro-Africana",
        chad: "Chade",
        comoros: "Comores",
        the_Republic_of_the_Congo: "República do Congo",
        democratic_Republic_of_the_Congo: "República Democrática do Congo",
        djibouti: "Djibuti",
        ecuador: "Equador",
        el_Salvador: "El Salvador",
        equatorial_Guinea: "Guiné Equatorial",
        eritrea: "Eritreia",
        fiji: "Fiji",
        gabon: "Gabão",
        gambia: "Gâmbia",
        greenland: "Groenlândia",
        guatemala: "Guatemala",
        guinea: "Guiné",
        haiti: "Haiti",
        isle_of_Man: "Ilha de Man",
        cote_d_Ivoire: "Costa do Marfim",
        jamaica: "Jamaica",
        jordan: "Jordânia",
        lebanon: "Líbano",
        lesotho: "Lesoto",
        liberia: "Libéria",
        libya: "Líbia",
        madagascar: "Madagáscar",
        malawi: "Malawi",
        maldives: "Maldivas",
        mali: "Mali",
        mauritania: "Mauritânia",
        mauritius: "Maurício",
        mozambique: "Moçambique",
        namibia: "Namíbia",
        nicaragua: "Nicarágua",
        republic_of_Niger: "República do Níger",
        north_Korea: "Coreia do Norte",
        reunion: "Reunião",
        san_Marino: "San Marino",
        senegal: "Senegal",
        sierra_Leone: "Serra Leoa",
        somalia: "Somália",
        sudan: "Sudão",
        suriname: "Suriname",
        eswatini: "Esvatini",
        syria: "Síria",
        togo: "Togo",
        tonga: "Tonga",
        tunisia: "Tunísia",
        united_States_Virgin_Islands: "Ilhas Virgens Americanas",
        uganda: "Uganda",
        uruguay: "Uruguai",
        vatican: "Vaticano",
        yemen: "Iémen",
        yugoslavia: "Iugoslávia",
        zambia: "Zâmbia",
        zimbabwe: "Zimbábue",
        china: "China",
    }
};
